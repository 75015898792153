<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="2">
                        <a-button type="primary" @click="handleMenuClick" v-if="isGranted('audit_task_detail_export')">
                            <a-icon type="import"/>
                            {{l('export')}}
                        </a-button>
                    </a-col>
                    <a-col :span="22" class="d-flex justify-content-end">
                        <a-range-picker @change="timeChange" :placeholder="[l('Review_start_time'), l('Review_end_time')]"
                                        style="margin-right:10px; width: 250px;"/>
                      <a-range-picker @change="timeChange1" :placeholder="[l('audit.planstart'), l('audit.planend')]"  v-model="defaultRangeTime"
                                      style="margin-right:10px; width: 250px;"/>
                        <a-tree-select
                                style="width: 200px;margin-right: 10px;"
                                :tree-data="workProcessData"
                                :replace-fields="treeReplace"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :placeholder="l('Please_select_a_audit_point')"
                                allow-clear
                                tree-default-expand-all
                                @change="workProcessClassChange"
                        >
                        </a-tree-select>
                        <a-input @click="selectMaterial" v-model="itemname"
                                 allowClear
                                 :placeholder="l('Please_select_a_audit_item')"
                                 @change="textChange"
                                 style="width: 200px;margin-right:10px;"></a-input>
                        <a-input-search
                                v-model.trim="filterText" enterButton
                                :placeholder="l('please_enter_Template&Inspector&Result&Note_to_search')"
                                style="width: 200px;" @search="filteronchange"

                        ></a-input-search>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns"
                             :data-source="tableData"
                             :customRow="customRow"
                             :rowKey="(record)=>record.id"
                             :scroll="{x:1000,y:610}"
                             :pagination="false"
                             size="small"
                             @change="handleTableChange"
                    >
                    <span slot="isSkipAuditPoint" slot-scope="text,record">
                        <a-icon type="check" v-if="record.isSkipAuditPoint" />
                        <a-icon type="close" v-else />
                    </span>
					<span slot="photo" slot-scope="text,record">
						<a @click.stop="photo(record)" v-if="record.picture!=null">
							<a-icon type="picture"/>
						</a>
					</span>
                        <span slot="action" slot-scope="text,record">
						<a @click.stop="view(record)">
							<a-icon type="search"/>{{l('View')}}
						</a>
					</span>
                    </a-table>
                </a-spin>
                <a-pagination
                        class="pagination"
                        :total="totalItems"
                        showSizeChanger
                        showQuickJumper
						:defaultPageSize="request.maxResultCount"
						:pageSizeOptions="pageSizeOptions"
                        :show-total="total => `共有 ${totalItems} 条`"
                        @change="onChangePage"
                        @showSizeChange="showSizeChange"/>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>

    import {AppComponentBase} from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {AuditTaskServiceProxy, AuditPointServiceProxy} from '../../../shared/service-proxies';
    import {ModalHelper} from '@/shared/helpers';
    import view from "../audit-task-view/check-the-details/check-the-details.vue"
    import photo from "../audit-task-view/photos/photos.vue"
    import moment from "moment";
    import materialModal from "./auditpoint/auditpoint.vue"
    import {fileDownloadService} from "../../../shared/utils";

    let _this;

    export default {
        name: 'audittaskdetail',
        mixins: [AppComponentBase],
        components: {
            view,
            photo
        },

        created() {
            _this = this;
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        async mounted() {
            // this.getData();
            this.getWorkProcessData();
            this.nowTime();
        },

        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,

                filterText: undefined,
                totalItems: 0,//总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                request: {maxResultCount: 20, skipCount: 0},

                tableData: [],
                columns: [
                    {title: this.l('layoutuser.isauditor'), width: 150, dataIndex: 'inspectorName', align: 'center',},
                    {title: this.l('FomartDateTime'), width: 150, dataIndex: 'fomartDateTime', align: 'center',},
                    {title: this.l('audit.name'),width: 150,dataIndex: 'name',align: 'center',ellipsis: true,},
                    {title: this.l('220'), width: 150, dataIndex: 'auditPointName', align: 'center', ellipsis: true,},
				    {title: this.l('IsSkipAuditPoint'), width: 100, dataIndex: 'isSkipAuditPoint', align: 'center',ellipsis: true,scopedSlots: {customRender: 'isSkipAuditPoint'}},
                    {title: this.l('222'), width: 110, dataIndex: 'auditItemTypeListName', align: 'center', ellipsis: true,},
                    {title: this.l('221'), width: 400, dataIndex: 'auditItemName', align: 'left', ellipsis: true,},

                    {title: this.l('Taskstatus'), width: 100, dataIndex: 'auditTaskStateName', align: 'center',},
                    {title: this.l('Auditresults'), width: 100, dataIndex: 'result', align: 'center',},
                    {title: this.l('Non-conformity'), width: 100, dataIndex: 'deviationTypeName', align: 'center',},
                    {title: this.l('Completetime'), width: 150, dataIndex: 'auditTime', align: 'center',sorter:true},
                    {title: this.l('Numericalvalue'), width: 150, dataIndex: 'data', align: 'center',},
                    {title: this.l('NOTE'), width: 300, dataIndex: 'note', align: 'left',},
                    {
                        title: this.l('Photo'),
                        width: 100,
                        dataIndex: 'photo',
                        align: 'center',
                        scopedSlots: {customRender: 'photo'}
                    },
                    {
                        title: this.l('Action'),
                        width: 80,
                        dataIndex: 'action',
                        align: 'center',
                        scopedSlots: {customRender: 'action'},
                        fixed: 'right'
                    },
                ],
                isLoading: false,//加载中
                workProcessData: [],
                workProcessClass: undefined,
                mPSQmCode: undefined,
                itemname: undefined,
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
              NowTime1: undefined,
              NowTime2: undefined,
              defaultRangeTime: [],
            }
        },
        methods: {

            handleTableChange(pagination, filters, sorter) {
                console.log(sorter);

                if (sorter.order != undefined){
                    this.Sorting = sorter.field + " " + sorter.order.replace("end","");
                }
                else{
                    this.Sorting = undefined;
                }

                this.getData();
            },

            //导出
            handleMenuClick() {
                this.AuditTaskServiceProxy.getToExcelAuditTaskDetail(
                    this.workProcessClass,
                    this.mPSQmCode,
                    this.startdate,
                    this.enddate,
                    this.planstartdate,
                    this.planenddate,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount,
                    this.selectedRowKey
                ).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
            },

            textChange(){
                if (this.mPSQmCode != undefined)
                {
                    this.mPSQmCode = undefined;
                    this.getData();
                }

            },
          nowTime() {
            let aData = new Date();
            this.NowTime1 = new Date(aData.setMonth(aData.getMonth() - 1));
            this.NowTime2 = new Date();
            this.defaultRangeTime = [moment(this.NowTime1),moment(this.NowTime2)];
            this.planstartdate = this.NowTime1;
            this.planenddate = this.NowTime2;
            this.getData();
          },
            getData() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAuditTaskDetail(
                    this.workProcessClass,
                    this.mPSQmCode,
                    this.startdate,
                    this.enddate,
                    this.planstartdate,
                    this.planenddate,
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items
                    this.tableData.map(item => {
                        item.startTime = item.startTime ? moment(item.startTime).format('YYYY-MM-DD') : "";
                        item.endTime = item.endTime ? moment(item.endTime).format('YYYY-MM-DD') : "";
                        item.auditTime = item.auditTime ? moment(item.auditTime).format('YYYY-MM-DD HH:mm') : "";
                    })
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            },
            //获取审核点
            getWorkProcessData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.workProcessData = JSON.parse(JSON.stringify(res));
                })
            },
            //审核项
            selectMaterial() {
                ModalHelper.create(materialModal, {}).subscribe((res) => {
                    if (res) {
                        console.log(res);
                        this.mPSQmCode = res.id;
                        this.itemname = res.name;
                        this.getData();
                    }
                });
            },
            workProcessClassChange(key) {
                console.log(key);
                this.workProcessClass = key;
                this.getData();
            },
            view(record) {
                ModalHelper.create(view, {
                    record: record, isView: false
                }, {width: 1000}).subscribe(res => {
                })
            },
            photo(record) {
                ModalHelper.create(photo, {
                    picture: record.picture, realPath: record.realPath, tempPath: record.tempPath
                }, {width: 800}).subscribe(res => {
                })
            },
            timeChange(date, dateString) {
                this.startdate = date[0];
                this.enddate = date[1];
                this.getData();
            },
          timeChange1(date, dateString) {
            this.planstartdate = date[0];
            this.planenddate = date[1];
            this.getData();
          },
            //分页
            onChangePage(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            //正常分页展示形式，显示（共有 {0} 条）
            // showTotalFun() {
            // 	console.log(this.totalItems);
            //     return this.l(
            //         "GridFooterDisplayText",
            //         this.totalItems
            //     );
            // },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
                this.getData();
            },
            // 搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
        },

    }
</script>
<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .con {
        padding-bottom: 10px;
    }
</style>
